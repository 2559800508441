import "../sass/app.scss";

// jQuery
import "./modules/jquery";

// Bootstrap
import "./modules/bootstrap";
// import "./modules/moment";

// Jquery-UI Dialog
import 'jquery-ui/ui/widgets/dialog.js';

// Input-Spinner
// import "./modules/input-spinner";

// Select2
import "./modules/select2";

// Daterangepicker
// import "./modules/daterangepicker";

// Full Calendar
// import "./modules/fullcalendar";

// Flatpickr
// import "./modules/flatpickr";

// Notyf
import "./modules/notyf";

// Stepper
// import "./modules/stepper";

// Leaflet
// import "./modules/leaflet";

// AmCharts
// import "./modules/amcharts";

// PDFMake
// import "./modules/pdfmake";

// JSZip
// import "./modules/jszip";

//List
import List from "list.js";
window.List = List;
